<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props" :custom-title="this.$t('label.role')">
    <Form style="width: 240px; margin-bottom: 8px;">
      <n-field-multiple-select v-model="roleSelected" :options="options" no-modified-icon :disabled="disabled" @on-change="changeData" />
    </Form>
    <table class="table table-bordered table-hover table-striped text-center n-table">
      <thead>
        <tr>
          <th>{{ $t('label.name') }}</th>
          <th>{{ $t('label.comment') }}</th>
          <th class="n-table-timestamp">{{ $t('label.lastUpdate') }}</th>
          <th v-rw class="action-remove">{{ $t('label.action') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in records.filter(v => v.checked)" :key="row.id">
          <td>{{ row.name }}</td>
          <td class="n-ellipsis" :title="row.comment">{{ row.comment }}</td>
          <td>{{ row.updateDatetime | datetime }}</td>
          <td v-rw>
            <n-button type="error" custom-icon="fas fa-trash-alt" text="remove" :disabled="disabled" @on-click="remove(row.id)" />
          </td>
        </tr>
      </tbody>
    </table>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { grant } from '@/api/operator/operator-operator';
import _ from 'lodash-es';

export default NModal.extend({
  name: 'ModalRoles',
  components: { NModal },
  props: {
    detail: Boolean,
    roleId: Number,
    records: Array,
    options: Array,
    selected: Array,
  },
  data() {
    return {
      roleSelected: this.selected,
    };
  },
  methods: {
    doValidate() {
      return Promise.resolve({ type: 'SUCCESS' });
    },
    doSubmit() {
      this.payload = { id: this.roleId, roles: this.roleSelected };
      return grant(this.payload);
    },
    changeData() {
      _.forEach(this.records, item => {
        item.checked = this.roleSelected.filter(v => v === item.id).length > 0;
      });
    },
    remove(id) {
      this.records.find(v => v.id === id).checked = false;
      this.roleSelected = this.records.filter(v => v.checked).map(v => v.id);
    },
  },
});
</script>

<style lang="scss" scoped>
::v-deep .ivu-form-item {
  margin: 0;
}
</style>
