<template>
  <!-- prettier-ignore -->
  <index-content>
    <template #options>
      <nb-download :url="OPERATOR_CSV_URL" :params="condition" />
      <nb-add @on-click="openModal" />
    </template>
    <search-condition>
      <ns-company v-model="condition.companyId" all-option @on-change="load" />
    </search-condition>
    <div class="table-responsive">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <tr>
            <th class="id">{{ $t('label.id') }}</th>
            <th class="col-weight-2">{{ $t('label.company') }}</th>
            <th class="col-weight-3">{{ $t('label.loginId') }}</th>
            <th class="col-weight-3">{{ $t('label.name') }}</th>
            <th class="col-weight-2">{{ $t('label.type') }}</th>
            <th class="col-weight-2">{{ $t('label.status') }}</th>
            <th class="col-weight-2">{{ $t('label.expirePolicy') }}</th>
            <th class="n-table-timestamp">{{ $t('label.expireTime') }}</th>
            <th v-table-action>{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.id }}</td>
            <td v-selection:[`company.company`]="row.companyId"></td>
            <td>{{ row.loginId }}</td>
            <td>{{ row.firstName }} {{ row.lastName }}</td>
            <td v-enums:AdminOperatorType="row.type"></td>
            <td v-enums:AdminOperatorStatus="row.status"></td>
            <td v-enums:AdminPasswordExpirePolicy="row.passwordExpirePolicy"></td>
            <td>
              <span v-if="passwordExpireTimeHide(row.passwordExpirePolicy, row.passwordExpireTime)">-</span>
              <span v-else>{{ row.passwordExpireTime | datetime }}</span>
            </td>
            <td class="n-button-box">
              <nb-modal text="roles" custom-icon="fa fa-user" :disabled="row.type === $enums.AdminOperatorType.ROOT" @on-detail="openRolesModal(row.id, true)" @on-edit="openRolesModal(row.id, false)" />
              <nb-modal @on-click="openModal(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import OperatorView from './view';
import { fetch, OPERATOR_CSV_URL, roles } from '@/api/operator/operator-operator';
import ModalOperator from './ModalOperator';
import ModalRoles from './ModalRoles';
import { isEmpty } from '@/helpers/utils';

export default OperatorView.extend({
  name: 'OperatorOperator',
  data() {
    return {
      preload: true,
      condition: {
        companyId: 0,
      },
      OPERATOR_CSV_URL,
    };
  },
  methods: {
    doLoad() {
      let params = { ...this.condition };
      return fetch(params);
    },
    parse(records) {
      this.records = records;
    },
    openModal(model = {}) {
      this.createModal(ModalOperator, { model, on: this });
    },
    openRolesModal(id, detail) {
      let roleId = typeof id === 'number' ? id : Number(id);
      roles(roleId).then(r => {
        let records = [];
        let options = [];
        let selected = [];
        if (!isEmpty(r)) {
          records = r;
          options = r.map(v => ({ name: v.name, value: v.id, disabled: v.disabled }));
          selected = r.filter(v => v.checked).map(v => v.id);
        }
        this.createModal(ModalRoles, { props: { detail, roleId, records, options, selected, escapable: true }, on: this });
      });
    },
    passwordExpireTimeHide(passwordExpirePolicy, passwordExpireTime) {
      if (isEmpty(passwordExpirePolicy)) return true;
      if (isEmpty(passwordExpireTime) || passwordExpireTime === '0') return true;
      return passwordExpirePolicy === this.$enums.AdminPasswordExpirePolicy.NEVER;
    },
  },
});
</script>

<style lang="scss" scoped>
.n-table {
  th.id {
    width: 5rem;
  }

  th.rw-action {
    width: 8.5rem;
  }

  th.ro-action {
    width: 9rem;
  }
}
</style>
