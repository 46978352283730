<template>
  <!-- prettier-ignore -->
  <n-modal v-bind="$props">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <n-field-id :value="`${model.id}`" label="id" :error="error.id" disabled />
        <ns-company v-model="model.companyId" :error="error.companyId" :disabled="model.type !== $enums.AdminOperatorType.MANAGER || isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-enum v-model="model.type" field-name="type" enum-name="AdminOperatorType" label="type" :filter="typeFilter" :error="error.type" :disabled="!isCreate || disabled" @on-change="changeType" />
        <n-field-enum v-model="model.status" field-name="status" enum-name="AdminOperatorStatus" label="status" :error="error.status" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-text v-model="model.firstName" field-name="firstName" label="firstName" :error="error.firstName" :disabled="disabled" />
        <n-field-text v-model="model.lastName" field-name="lastName" label="lastName" :error="error.lastName" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-text v-model="model.loginId" field-name="loginId" label="loginId" :error="error.loginId" :disabled="!isCreate || disabled" />
        <n-field-mask-enum v-model="model.loginRestriction" field-name="loginRestriction" type-name="AdminLoginRestriction" label="loginRestriction" :error="error.loginRestriction" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <n-field-password v-model="model.password" field-name="password" label="password" :lock-btn="lock" autocomplete="new-password" :error="error.password" :disabled="disabled" :lock-disabled="isCreate" @on-lock-btn="onLock" />
        <n-field-enum v-model="model.passwordExpirePolicy" field-name="passwordExpirePolicy" enum-name="AdminPasswordExpirePolicy" label="expirePolicy" :error="error.passwordExpirePolicy" :disabled="!isCreate || disabled" />
      </n-row-col2>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { create, update, validate } from '@/api/operator/operator-operator';
import { mapGetters } from 'vuex';

export default NModal.extend({
  name: 'ModalOperator',
  components: { NModal },
  data() {
    return {
      lock: 'lock',
    };
  },
  computed: {
    ...mapGetters('profile', ['isAdmin', 'isManager']),
    typeFilter() {
      if (this.isCreate && this.isAdmin) return [this.$enums.AdminOperatorType.ROOT];
      if (this.isCreate && this.isManager) return [this.$enums.AdminOperatorType.ROOT, this.$enums.AdminOperatorType.ADMIN];
      return [];
    },
  },
  mounted() {
    this.lock = this.isCreate ? 'unlock' : 'lock';
  },
  methods: {
    doValidate(model) {
      return validate(model);
    },

    onLock(v) {
      if (!this.isEdit) return;
      this.$set(this.model, 'password', v === 'unlock' ? '' : undefined);
    },

    doSubmit(model) {
      return this.isCreate ? create(model) : update(model);
    },

    changeType(type) {
      if (type !== this.$enums.AdminOperatorType.MANAGER) {
        this.$set(this.model, 'companyId', null);
        this.$set(this.error, 'companyId', null);
      }
    },
  },
});
</script>
