import { $fetch, $post, $put } from '@/services/axios';

export function fetch(params) {
  return $fetch(`operator/operators`, params);
}

export const OPERATOR_CSV_URL = `operator/operators/csv`;

export function validate(model) {
  return $post(`operator/operator/validate`, model);
}

export function create(model) {
  return $put(`operator/operator`, model);
}

export function update(model) {
  return $post(`operator/operator/${model.id}`, model);
}

export function roles(id) {
  return $fetch(`operator/operator/${id}/roles`);
}

export function grant(model) {
  return $post(`operator/operator/${model.id}/roles`, {
    roles: model.roles.toString(),
  });
}
